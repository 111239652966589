.form-block-fields{
    flex:1 1 0;
    padding:var(--gutter);
    text-align:left;
    padding:calc(var(--gutter)*0.5) 0 calc(var(--gutter)*0.5) calc(var(--gutter));
}

/* size PORTABLE */
@media only screen and (max-width: 920px) {
    .form-block-fields{
        padding-left:0;
        padding-top:calc(var(--gutter));
    }
}
