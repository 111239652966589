.box-error{
    text-align: center;
    padding:var(--vertical-unit);
    margin-bottom: calc(var(--vertical-unit)*2);
}

.box-error .link{
    display:inline-block;
    pointer-events: all;
    cursor: pointer;
    background-color:var(--color-secondary);
    height:calc(var(--vertical-unit)*1.5);
    line-height:calc(var(--vertical-unit)*1.5);
    padding:0 var(--gutter);
    color:var(--color-copy-inv);    
    text-align: center;
    user-select: none;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}
    
.box-error .link:hover{
    text-decoration: none;
    background-color:var(--color-secondary-lighter);
}