.form-wrapper{
    margin:0 0 calc(var(--vertical-unit)*4) 0;
}

form{
    
}

.columns-wrapper{
    display:flex;
    flex-direction: row;
    gap:var(--gutter);
}

.columns-wrapper .column{
    margin-bottom:calc(var(--vertical-unit)*2);
    flex:1 1 50%;    
}

/* size PORTABLE */
@media only screen and (max-width: 920px) {
    .columns-wrapper{
        flex-direction: column;
        gap:0;
        margin-bottom:calc(var(--vertical-unit)*2);
    }
    
    .columns-wrapper .column{
        flex:1 1 100%;    
        margin-bottom:0;
    }
}


table.confirm{
    width:100%;
    text-align: left;
    border-spacing: 0px;
}

table.confirm th, table.confirm td{
    border-bottom:1px solid var(--color-copy-alt);
    height:calc(var(--vertical-unit)*2);
    padding:0;
}

table.confirm th{
    color:var(--color-hyperlink);
    font-weight:500;
    width:35%;
}

table.confirm td{
    width:65%;
}