[class^='title-size']{
    margin:0;
}

h1, .title-size1{
  font-size:var(--font-size-title1);
  line-height:var(--font-lh-title1);
  font-weight: 500;
  margin-top:calc(var(--vertical-unit)*0.5);
  margin-bottom:calc(var(--vertical-unit)*1);
}

h2, .title-size2{
  font-size:var(--font-size-title2);
  line-height:var(--font-lh-title2);
  font-weight: 400;
  margin-top:calc(var(--vertical-unit)*1);
  margin-bottom:calc(var(--vertical-unit)*1);
}

h3, .title-size3{
  font-size:var(--font-size-title3);
  line-height:var(--font-lh-title3);
  font-weight: 400;
  margin-bottom:calc(var(--vertical-unit)*1);
}

h4, .title-size4{
  font-size:var(--font-size-title4);
  line-height:var(--font-lh-title4);
  font-weight: 400;
  margin-bottom:calc(var(--vertical-unit)*0.5);
}

