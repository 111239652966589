.form-block{
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items: stretch;
  gap:0;
  margin-bottom:var(--vertical-unit);
}

/* size PORTABLE */
@media only screen and (max-width: 920px) {
  .form-block{
    flex-direction:column;
  }
}