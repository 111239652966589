.logo{
    margin-top:calc(var(--vertical-unit)*1);
}

.logo [class^='title-size']{
    text-align: center;
}

.logo .logo-wrapper{
    position:relative;
    height:calc(var(--vertical-unit)*9);
}

.logo .logo-wrapper img{
    position: relative;
    top:0; left:0;
    width:100%; height:100%;
    user-select: none;
}

/* size PORTABLE */
@media only screen and (max-width: 920px) {
    .logo{
        margin-top:calc(var(--vertical-unit)*0.5);
    }
    
    .logo .logo-wrapper{
        position:relative;
        height:calc(var(--vertical-unit)*7);
    }
}   