:root {
  --vertical-unit: 27px;
  --content-max-width: 1200px;
  --maincontent-gutter:40px;
  --border-radius-default:5px;
  --gutter:30px;
  
  --color-page: #FFFF;
  --color-page-dark:#f8f9fa;
  --color-page-darker:#e8ebee;
  --color-page-darkest:#aaa9aa;
  
  --color-primary:#090928;
  --color-primary-light:#5954a3;
  
  --color-secondary:#00D9C7;
  --color-secondary-lighter:#19ead9;
  
  --color-copy: #090928;
  --color-copy-inv: #FFFFFF;
  --color-copy-disabled:#c0c5ca;
  --color-copy-alt: #dee1e5;
  --color-hyperlink: #00D9C7;
  --color-error:#db3445;
  --color-success:#00D9C7;
  
  --font-size-copysmaller:13px;
  --font-lh-copysmaller:20px;
  
  --font-size-copy:16px;
  --font-lh-copy:23px;
  
  --font-size-title4:20px;
  --font-lh-title4:27px;
  
  --font-size-title3:24px;
  --font-lh-title3:32px;
  
  --font-size-title2:28px;
  --font-lh-title2:38px;
  
  --font-size-title1:34px;
  --font-lh-title1:42px;
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Mabry Pro', sans-sarif;
  font-size:var(--font-size-copy);
  font-weight:400;
}

body {
  margin: 0;
  background-color: var(--color-page);
  color: var(--color-copy);
  font-size:var(--font-size-copy);
  line-height: var(--font-lh-copy);
}

a{
  color:var(--color-hyperlink);
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

p{
    margin:0 0 var(--vertical-unit) 0;
}

/* size PALM */
@media only screen and (max-width: 600px) {
  p{
    margin-bottom:calc(var(--vertical-unit)*0.5);
  }
}

.smalltext{
  font-size:var(--font-size-copysmaller);
  line-height:var(--font-lh-copysmaller);
}