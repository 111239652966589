.form-navigation{
    text-align: center;
    text-transform: uppercase;
    margin:calc(var(--vertical-unit)*3) auto calc(var(--vertical-unit)*3);
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    max-width:1000px;
    font-size:var(  --font-size-copysmaller);
    user-select: none;  
}

/* size PORTABLE */
@media only screen and (max-width: 920px) {
  .form-navigation{
    margin:calc(var(--vertical-unit)*2) auto calc(var(--vertical-unit)*3);
  }
}   

.form-navigation .form-navigation-item{
    flex:1 1 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.form-navigation .form-navigation-item:after{
    content:'';
    position: absolute; right:50%; top:11px;
    width:100%;
    height:2px;
    background:var(--color-page-darkest);
    z-index:1;
}

.form-navigation .form-navigation-item:first-child:after{
    content:none;
}
    
.form-navigation .form-navigation-item .index{
    background:var(--color-page-darker);
    color:var(--color-copy);
    border-radius:50%;
    width:25px; height:25px;
    line-height:1.9;
    margin-bottom:10px;
    position:relative;
    z-index:10;
}

.form-navigation .form-navigation-item.active:after{
    background:var(--color-primary);
}

.form-navigation .form-navigation-item.fail.active:after{
  background:var(--color-error);
}

.form-navigation .form-navigation-item.active .index{
    background:var(--color-primary);
    color:var(--color-copy-inv);
}

.form-navigation .form-navigation-item.fail.active .index{
  background:var(--color-error);
}

.form-navigation .form-navigation-item .title{
    letter-spacing: 1px;
    position:absolute;
    z-index: 10;
    top: 34px;
    padding: 0 var(--gutter);
}

/* size PALM */
@media only screen and (max-width: 600px) {
  .form-navigation .form-navigation-item .title{
    padding: 0 calc(var(--gutter)*0.5);
  }
}