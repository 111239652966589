.form-page{
}

.form-page.hidden{
    display:none;
}

.form-page .note{
    width:100%;
    text-align: right;
    color:var(--color-hyperlink);
    margin-bottom:var(--vertical-unit);
}

.form-page .buttons-wrapper{
    display:flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
}

.form-page .button-container, .form-page .button{
    flex:0 1 auto;
    width:40%;
    max-width:350px;
    min-width:100px;
    border-radius:var(--border-radius-default);
}

.form-page .button-container{    
    background-color:var(--color-page-dark);
    padding:var(--gutter);
}

.form-page .button{
    display:inline-block;
    pointer-events: all;
    cursor: pointer;
    background-color:var(--color-secondary);
    height:calc(var(--vertical-unit)*1.5);
    line-height:calc(var(--vertical-unit)*1.5);
    color:var(--color-copy-inv);    
    text-align: center;
    user-select: none;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.form-page .button.submit{
    height:calc(var(--vertical-unit)*2);
    line-height:calc(var(--vertical-unit)*2);
    font-size:var(--font-size-title3);
}

.form-page .button:hover{
    background-color:var(--color-secondary-lighter);
}

.form-page .button-container .button{
    width:100%;
}

/* size PORTABLE */
@media only screen and (max-width: 920px) {
    .form-page .buttons-wrapper{
        gap:var(--gutter);
    }
    
   .form-page .button-container, .form-page .button{
        width:50%;
        max-width:50%;
   }
}

/* size PALM */
@media only screen and (max-width: 600px) {
    .form-page .buttons-wrapper{
        flex-direction: column-reverse;
        gap:var(--vertical-unit);
    }
    
    .form-page .button-container, .form-page .button{
        width:100%;
        max-width:100%;
    }
}

