.content-wrapper{
    max-width:var(--content-max-width);
    margin:0 auto 0;
    padding:0 var(--maincontent-gutter);
    position:relative;
    width:100%;
    height:100%;
    flex: 0 1 0;
}

/* size PALM */
@media only screen and (max-width: 600px) {
  .content-wrapper{
    padding:0 calc(var(--maincontent-gutter)*0.5);
  }
}