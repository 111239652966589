.form-field{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height:auto;
    margin-bottom:calc(var(--vertical-unit)*0.5);
    gap:calc(var(--gutter)*0.5);
    position:relative;
}

.form-field.checkbox{
    flex-direction: row !important;
}
    
/**** LABELS ****/
.form-field label{
    flex:0 1 35%;
    max-width:200px;
    min-width:100px;
    padding-top:6px;
}

.form-field label.checkboxLabel,.form-field label.radioLabel{
    flex:1 1 0;
    max-width: unset;
    padding-top:2px;
}


/**** FORM-FIELD-CHECKBOX & RADIO ****/
.form-field .form-field-checkbox, .form-field .form-field-radio{
    flex:0 1 var(--gutter);
}

.form-field .form-field-checkbox input[type=checkbox]{
    width:20px; height:20px;
}


/**** FORM-FIELD-SELECT ****/
.form-field .form-field-select{
    flex:1 1 0;
    height:auto;
    position: relative;
}

.form-field .form-field-select select{
    width:100%;
    height:calc(var(--vertical-unit)*1.5);
    border-radius:var(--border-radius-default);
    padding:5px 10px;
    font-size:var(--font-size-copy);
    border:1px solid var(--color-copy-disabled);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    outline: none;
}

.form-field .form-field-select select:invalid{
    color:var(--color-copy-disabled);
}


/**** FORM-FIELD-INPUT ****/
.form-field .form-field-input{
    flex:1 1 0;
    height:auto;
    position: relative;
}

.form-field .form-field-input input{
    width:100%;
    height:calc(var(--vertical-unit)*1.5);
    border-radius:var(--border-radius-default);
    padding:5px 10px;
    font-size:var(--font-size-copy);
    border:1px solid var(--color-copy-disabled);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    outline: none;
}

.form-field .form-field-input input:focus{
    outline: none;
    box-shadow: 0 0 4px black;
    border-color:var(--color-copy);
}


/* size PALM */
@media only screen and (max-width: 600px) {
    .form-field{
       flex-direction: column;
       gap:4px;
       margin-bottom:0;
    }
    
    .form-field label, .form-field .form-field-select, .form-field .form-field-input{
        max-width:100%;
        width:100%;
    }
}

/**** ERRORS & NOTES ****/
.form-field.error .form-field-input input, .form-field.error .form-field-select select{
    border:1px solid red;
}

.form-field .txt-error{
    position: absolute; top:calc(var(--vertical-unit)*1.5); left:0;
    width:100%;
    color:red;
    opacity:0;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}
.form-field.error .txt-error{
    opacity:1;
}

.form-field .txt-notes{
    padding-top:calc(var(--vertical-unit) + 3px);
    font-size:var(--font-size-copysmaller);
    line-height:var(--font-lh-copysmaller);
}

.form-field .txt-notes p{
    margin-bottom:calc(var(--vertical-unit)*0.5);
}


/**** HELP ****/
.icon-help{
    position: relative;
    width:22px; height:22px;
    display:inline-block;
    background:var(--color-primary);
    border-radius:50%;
    margin-left:8px;
    top:-2px;
    color:var(--color-copy-inv);
    text-align: center;
    font-size:var(--font-size-copysmaller);
    line-height:21px;
    cursor:pointer;
    user-select: none;
    font-family: Arial, Helvetica, sans-serif
}

.box-help{
    position: absolute; left:0; top:60%;
    width:50%; height:auto;
    min-width:250px;
    background-color:var(--color-primary-light);
    color:var(--color-copy-inv);
    padding:calc(var(--gutter)*0.5);
    z-index:100;
    box-shadow:0 0 4px rgba(0,0,0,0.5);
    pointer-events:none;
    font-size:var(--font-size-copysmaller);
    line-height:var(--font-lh-copysmaller);
    opacity:0;
    cursor: default;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
}

.box-help.visible{
    opacity:1;
    cursor: pointer;
    pointer-events:all;
}