.header{
	height: calc(var(--vertical-unit)*3);
    line-height:calc(var(--vertical-unit)*3);
	background-color:var( --color-page-dark);
    font-size:var(--font-size-title2);
    text-align: right;
    font-weight:500;
}

/* size PORTABLE */
@media only screen and (max-width: 920px) {
    .header{
        height: calc(var(--vertical-unit)*2);
        line-height:calc(var(--vertical-unit)*2);
        font-size:var(--font-size-title3);
        text-align: center;
    }
}

.header a{
    text-decoration: none;
    color:var(--color-copy);
}