.form-block-description{
  width:35%;
  max-width:300px;
  min-width:150px;
  flex:0 1 auto;
  background-color:var(--color-primary);
  color:var(--color-copy-inv);
  padding:var(--gutter);
  text-align:left;
}


/* size PORTABLE */
@media only screen and (max-width: 920px) {
  .form-block-description{
    max-width:100%;
    width:100%;
    padding:var(--gutter*0.5);
  }
  
  .form-block-description p:last-child{
    margin-bottom:0;
  }
}